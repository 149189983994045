<script>
import { GET_USER_BUSINESS_DATA } from '../browser-db-config/localStorage';
export default {
  data() {
    return {
      userData: null,
      url: process.env.VUE_APP_GWT_URL
    };
  },
  created() {
    this.userData = GET_USER_BUSINESS_DATA();
  }
}
</script>
  
<template>
  <div class="">
    <iframe v-if="userData" :src="`${url}?email=${userData.email}`" frameborder="0"
      class="w-full h-screen"></iframe>
  </div>
</template>